<template>
<v-container ref="pageBrands" fluid tag="section">
    <material-card icon="mdi-clipboard-text" icon-small title="Бренди" color="accent" class="mb-16">
    </material-card>
    <v-simple-table>
        <thead>
            <tr>
                <th class="primary--text" width="20">
                    ID
                </th>
                <th class="primary--text" width="40%">
                    Назва
                </th>
                <th class="primary--text" width="40%">
                    Зображення
                </th>
                <th class="primary--text" width="10">
                    Включено
                </th>
                <th width="50"></th>
            </tr>
        </thead>

        <tbody v-if="brands.length">
            <tr v-for="(brand, index) in brands" :key="brand.id">
                <td>{{ brand.id }}</td>
                <td>{{ brand.name }}</td>
                <td>
                  <v-img contain height="50" width="200" :src="`${imageBaseUrl}${brand.image}`"></v-img>
                </td>
                <td><v-icon v-if="brand.enabled">mdi-check</v-icon></td>
                <td>
                    <span class="d-flex">
                        <v-icon v-if="isManager || isAdmin" class="mr-4" color="green" @click="$router.push(`/brands/${brand.id}/`)">
                            mdi-pencil
                        </v-icon>
                        <v-icon v-if="isAdmin" color="error" @click="deleteBrand(brand.id)">
                            mdi-delete
                        </v-icon>
                    </span>
                </td>
            </tr>
        </tbody>
    </v-simple-table>

    <!--Пагинация-->
    <div v-if="pagesCount >= 1" class="text-center py-4">
        <v-pagination @input="setQueryParam(pageCurrent)" v-model="pageCurrent" :total-visible="9" :length="pagesCount"></v-pagination>
    </div>
    <DialogConfitmDelete ref="dialogConfirmDelete" />
    <Preloader v-if="isSending" />
    <v-btn
      v-if="isManager || isAdmin"
      class="add-user__button"
      fab
      dark
      color="primary"
      to="/brands/add/"
    >
      <v-icon dark>
        mdi-plus
      </v-icon>
    </v-btn>


</v-container>
</template>
<script>
import { HTTP } from '@/http-common';
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { IMAGE_BASE_URL } from '@/env.config';

  export default {
    name: 'RegularTablesView',
    data() {
      return {
        // Список брендів
        brands: [],
        // Тецущий проект
        project: {},
        mis: null,
        // Текущая страница
        pageCurrent: null,
        // Количество акций на странице
        pageLimitItems: 10,
        // Количество страниц
        pagesCount: 1,
        // Выполняется отправка на сервер
        isSending: false,
        imageBaseUrl: `${IMAGE_BASE_URL}brands/`
      }
    },
    computed: {
      ...mapGetters(['isManager', 'isAdmin', 'projects']),
    },

    mounted() {
      this.init();
    },

    watch: {
      // Сделано через watch  чтобы отслеживать переходы вне страницы (например если пользователь нажмёт в меню /brands/)
      '$route': {
        handler() {
          this.getBrands();
        },
        deep: true,
      }
    },

    methods: {
      ...mapMutations(['setSnackbar']),
      ...mapActions(['getProjects']),

      async init() {
        await this.getProjects();
        await this.getBrands();
      },

      // Получить список 
      async getBrands() {
        let { project = null, type = null, mechanic = null, mis = null, page = 1 } = this.$route.query;
        this.project = this.projects.find((el) => el.id == project);
        this.mis = mis;
        this.pageCurrent = +page;

        try {
          let { data } = await HTTP.post('/get-list-brands', { 
            limit: this.pageLimitItems,
            page,
          });
  
          this.brands = data?.content?.data || [];
          this.pagesCount = data?.content?.pagination?.pages || 1;
        } catch(error) {
          console.warn(error);
        }
      },

      // Добавляем query параметры в урл
      setQueryParam(page = 1) {
        this.pageCurrent = page;
        let query = {
          project: this.project?.id,
          mis: this.mis,
          page: this.pageCurrent
        };

        for(let key in query) {
          if (!query[key] || (key === 'page' &&  query?.page <= 1)) {
            delete query[key];
          }
        };

        let query1 = JSON.stringify(query).split('"').join('');
        let query2 = JSON.stringify(this.$route.query).split('"').join('');

        if (query1 != query2) {
          this.$router.push({ query });
        }
        return query;
      },

      // Удалить brand
      async deleteBrand(brandId) {
        let isConfirmDelete = await this.$refs.dialogConfirmDelete.callDialog();
        if (!isConfirmDelete) return;
        this.isSending = true;

        try {
          await HTTP.post('/delete-brand', { id: brandId });
          this.setSnackbar({ type: 'success', text: 'Успiшно видалено' });
          this.getBrands();
        } catch(error) {
          console.warn(error);
          this.setSnackbar({ type: 'error', text: error?.response?.data?.message || 'Помилка сервера' });
        } finally {
          this.isSending = false;
        }
      },
    },
  }

</script>

<style>
.add-user__button {
  position: fixed;
  bottom: 20px;
  right: 20px;
}
</style>

